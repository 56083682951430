import { Box } from '@material-ui/core'
import Accordion from './accordion'

export default function KidsCornerContent() {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ marginTop: '0.1rem' }}
      />
      <Accordion />
      {/* <Footer /> */}
    </>
  )
}
