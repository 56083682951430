import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  styled,
  Typography,
} from '@material-ui/core'
import AccordionCard from '../accordion-card'

const AccordionContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  padding-left: 4rem;
  padding-right: 4rem;
`

const AccordionTextContainer = styled(Box)`
  margin-top: 2rem;
`
const AccordionLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1652)]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '1.2rem',
      },
    }
  }}
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: #008174;
  padding-left: 16px;
`

export const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        width: '90%',
        textAlign: 'center',
      },
    }
  }}

  margin-bottom: 1rem;
  margin-top: 1rem;
`
export const ButtonContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center',
      },
    }
  }}
`
export const StyledAccordion = styled(Accordion)`
  &::before {
    position: relative;
  }
  padding: 0;
  margin: 2px !important;
`
export const StyledAccordionSummary = styled(AccordionSummary)``

export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        lineHeight: '1.8',
        marginBottom: '0.3rem',
      },
    }
  }}
  padding-left: 16px;
  line-height: 2.1;
`
export const LastTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        lineHeight: '1.8',
        marginBottom: '2rem',
      },
    }
  }}
  padding-left: 16px;
  line-height: 2.1;
  margin-bottom: 1rem;
`

export default function AccordionFeature() {
  return (
    <AccordionContainer>
      <AccordionTextContainer>
        <AccordionLabelTypography>Code of conduct</AccordionLabelTypography>
        <LastTypography>
          Tri Tag Rugby’s Code of Conduct reflects our vision, ethics, morals
          and beliefs regarding the experience a participant should expect
          through participation in Tri Tag Rugby.
        </LastTypography>
        <LastTypography>
          The principles of fun, inclusiveness, fairness, respect,
          responsibility and safety should be taken seriously. This Code aims to
          ensure our core values, positive behaviours and attitudes are
          maintained and that everyone involved in our sport is aware of his or
          her legal and ethical rights and responsibilities.
        </LastTypography>
        <LastTypography>
          This Code of Conduct has been endorsed by the TTR Board and it may be
          amended from time to time by TTR. Copies of the Code can be obtained
          from our website www.tritagrugby.com.
        </LastTypography>
        <LastTypography>
          This Code of Conduct applies to all individuals and entities involved
          with Tri Tag Rugby. The Core Code must be observed by all without
          exception. The role specific Supplement Codes are to be applied as
          additional requirements to the Core Code to be observed by individuals
          involved in Tri Tag Rugby.
        </LastTypography>
      </AccordionTextContainer>
      <AccordionCard
        label="The Core Code"
        description={[
          '* Operate within the rules, policies, regulations, and spirit of Tri Tag Rugby, promoting inclusiveness, safety, fun and fair play over winning at any cost or personal gain.',
          '* Encourage and support opportunities for people to learn appropriate behaviours and skills while enjoying themselves.',
          '* Support opportunities for participation in all aspects of Tri Tag Rugby.',
          '* Treat each person with respect and as an individual.',
          '* Display control and courtesy to all involved with Tri Tag Rugby.',
          '* Respect the rights and worth of every person regardless of their gender, ability, cultural background or religion.',
          '* Respect the decisions of officials, coaches and administrators in the conduct of Tri Tag Rugby adopt appropriate and responsible behaviour in all interactions, including online activity.',
          '* Adopt responsible behaviour in relation to alcohol and other drugs. ',
          '* Ensure your decisions and actions contribute to a harassment free, safe and inclusive environment do not tolerate harmful or abusive behaviours.',
          '* Take reasonable care for his or her own health and safety, and ensure that his or her acts or omissions do not adversely affect the health and safety of others.',
          '* Do not engage in activities that may bring yourself or Tri Tag Rugby into disrepute act fairly and impartially, avoid bias, discrimination or self-interest respect the intellectual property rights of Tri Tag in relation to Tri Tag Rugby’s resources, materials, uniforms and works.',
        ]}
      />
      <AccordionCard
        label="Volunteers Code"
        description={[
          '* Use volunteer positions appropriately, do not use the position as an important volunteer to seek an undue advantage for yourself, family members or associates, or to cause detriment to TTR; ensure that volunteers decline gifts or favours that may cast doubt on your ability to apply independent judgement.',
          '* Avoid real or apparent conflicts of interest.',
          '* Act in a financially responsible manner.',
          '* Exercise due care, diligence and skill, ascertain all relevant information, make reasonable enquiries, and understand the financial, strategic and other implications of decisions.',
          `* Demonstrate leadership and stewardship, promote and support the application of TTR's structure and values.`,
          `* Comply with TTR’s codes, policies and all legislative and regulatory requirements for the position.`,
          `* Ensure adequate protection of Tri Tag Rugby's intellectual property.`,
        ]}
      />
      <AccordionCard
        label="Athletes Code"
        description={[
          '* Prioritise safety, fun and inclusiveness above anything else.',
          '* Participate for your own enjoyment and benefit refrain from any behaviour that may bring yourself, Tri Tag Rugby or its authorised licensees into disrepute.',
          '* Exhibit exemplary sportsmanship at all times.',
        ]}
      />
      <AccordionCard
        label="Coaches Code"
        description={[
          '* Place the safety, welfare, inclusiveness and enjoyment of participants above all else.',
          '* Be aware of and support the return to play guidelines.',
          '* When/if judging your performance, base it on the success of players that you coached with the objective of them returning to play Tri Tag Rugby, not based on results.',
          '* Promote respect for your opponents, referees and all officials.',
          '* Any training session(s) conducted should be a balance of fun, skill development and physical exertion.',
        ]}
      />
      <AccordionCard
        label="Officials Code"
        description={[
          `* Place the safety, welfare and enjoyment of all involved above all else.`,
          '* Commit to providing a quality service to participants.',
          '* Make sure fields are safe and free of any hazards.',
          '* Be consistent and impartial when making decisions.',
          '* Address unsporting behaviour and promote respect for all people.',
          '* Avoid any situation which may lead to a conflict in interest.',
          '* Obtain appropriate qualifications and keep up-to-date with the latest refereeing practices.',
        ]}
      />
      <AccordionCard
        label="Administrators Code"
        description={[
          '* Act honestly, in good faith and in the best interests of the sport as a whole.',
          '* Ensure that any information acquired or advantage gained from the position is not used improperly.',
          '* Conduct your responsibilities with due care, competence and diligence.',
          '* Do not allow prejudice, conflict of interest or bias to affect your objectivity.',
        ]}
      />
      <AccordionCard
        label="Parents Code"
        description={[
          '* Encourage children to participate and have fun.',
          '* Provide a model of good sportsmanship for your child to copy.',
          `* Focus on the child 's interaction and enjoyment rather than winning or losing.`,
          '* Never ridicule or yell at a participant for making a mistake or losing a competition.',
        ]}
      />
      <AccordionCard
        label="Spectators Code"
        description={[
          '* Respect the performances, efforts and feelings of all people.',
          '* Do not use, and reject the use of, violence or abuse in any form, whether it is by spectators, coaches, officials or players.',
          `* Take reasonable care for his or her own health and safety, and ensure that his or her acts or omissions do not adversely affect the health, safety and enjoyment of others.`,
        ]}
      />
    </AccordionContainer>
  )
}
