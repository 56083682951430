import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        width: '90%',
        textAlign: 'center',
      },
    }
  }}

  margin-bottom: 1rem;
  margin-top: 1rem;
`
export const ButtonContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center',
      },
    }
  }}
`
export const StyledAccordion = styled(Accordion)`
  &::before {
    position: relative;
  }
  padding: 0;
  margin: 2px !important;
`
export const StyledAccordionSummary = styled(AccordionSummary)`
  margin: 0;
`
export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        lineHeight: '1.8',
        marginBottom: '1rem',
      },
    }
  }}
  line-height: 2.1;
`

interface AccordionCardProps {
  label: string
  description: string[]
}

function AccordionCard({ label, description }: AccordionCardProps) {
  return (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ fontWeight: 'bolder' }}>{label}</Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        {description &&
          description.map((value: string) => (
            <StyledTypography key={value}>{value}</StyledTypography>
          ))}
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default AccordionCard
